<template>
  <div>
    <b-row>
      <b-col md="1.5">
        <h2>Case Level</h2>
      </b-col>
      <b-col
        md="6"
        class="pb-1"
      >
        <b-form-tags
          v-model="QueriesSelectedTags"
          no-outer-focus
          class="mb-2"
        >
          <template v-slot="{ tags, tagVariant }">
            <div
              class="d-inline-block"
              style="font-size: 1.5rem;"
            >
              <b-form-tag
                v-for="tag in tags"
                :key="tag"
                :title="tag"
                :variant="tagVariant"
                class="mr-1"
                @remove="removeTag(tag)"
              >{{ tag }}</b-form-tag>
            </div>
          </template>
        </b-form-tags>
      </b-col>
      <b-col
        md="1"
        class="pl-2 pr-2 d-flex justify-content-around"
      >
        <div
          class="cursor-pointer clear-all-btn"
          @click="clearAll"
        >Clear All</div>
      </b-col>
      <b-col>
        <b-row>
          <div class="center-justified">
            <toggle-button
              :value="!onlyActiveCases"
              :sync="true"
              @change="onlyActiveCases = !$event.value"
            />
            Include closed cases (60 days)
          </div>
        </b-row>
        <b-row>
          <div class="center-justified">
            <toggle-button
              :value="fitToScreen"
              :sync="true"
              @change="fitToScreen = !fitToScreen"
            />
            Fit to screen
          </div>
        </b-row>
      </b-col>

    </b-row>
    <b-row>
      <b-col
        md="3"
        class="w-25 pl-1 pr-1"
      >
        <v-select
          id="searchItem"
          v-model="SearchItem"
          label="title"
          :options="searchItemOptions"
          placeholder="Select Filter Here"
          name="searchItem"
        />
      </b-col>
      <b-col md="5">
        <v-select
          v-if="subSearchItemOptions !== null"
          id="subSearchItem"
          v-model="SubSearchItem"
          label="title"
          :options="subSearchItemOptions"
          :multiple="true"
          placeholder="Select Filter Here"
          name="subSearchItem"
        >
          <template #search="{ attributes, events }">
            <input
              v-model="SubSearch"
              class="vs__search"
              v-bind="attributes"
              v-on="events"
            >
          </template>
        </v-select>
        <div
          v-else
        >
          <b-row>
            <b-col>
              <b-form-group
                label="Start Date"
                label-for="startDate"
                class="date-input"
              >
                <b-form-input
                  id="startDate"
                  v-model="startDateItem"
                  type="date"
                  placeholder="Start Date"
                  name="startDate"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="End Date"
                label-for="endDate"
                class="date-input"
              >
                <b-form-input
                  id="endDate"
                  v-model="endDateItem"
                  type="date"
                  placeholder="End Date"
                  name="endDate"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col
        md="1"
        class="d-flex justify-content-around"
      >
        <div
          class="cursor-pointer"
          @click="saveQuery"
        >
          <feather-icon
            icon="SaveIcon"
            size="35"
            style="color: #476dae"
          />
        </div>
        <div
          v-if="QueriesSelect && QueriesSelect.filter && QueriesSelect.filter.id"
          class="cursor-pointer ml-1"
          @click="deleteQuery"
        >
          <feather-icon
            icon="Trash2Icon"
            size="35"
            style="color: #476dae"
          />
        </div>
      </b-col>
      <b-col
        md="3"
        class="w-25 pl-1 pr-1"
      >
        <v-select
          id="queriesSelect"
          v-model="QueriesSelect"
          label="title"
          :options="queriesSelectOptions"
          placeholder="Stored Queries here"
          name="queriesSelect"
        />
      </b-col>
    </b-row>

    <div class="pt-1">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: false,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        @on-sort-change="onSortChange"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: InternalNumber -->
          <span v-if="props.column.field === 'fileNumber'">
            <router-link
              :to="'mycases/cases/' + props.row.caseID + '/detail'"
              target="_blank"
            >{{ props.row.fileNumber }}</router-link>
          </span>

          <!-- Column: DateCreated -->
          <span
            v-else-if="props.column.field === 'dateCreated'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.dateCreated | dateFormat }}</span>
          </span>

          <!-- Column: Action Due Dates -->
          <span
            v-else-if="props.column.field === 'actionDueDates'"
          >
            <span>{{ props.row.actionDueDates }}</span>
          </span>

          <!-- Column: Budget Dollars -->
          <span
            v-else-if="props.column.field === 'budgetDollars'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.budgetDollars }}</span>
          </span>

          <!-- Column: Date Due -->
          <span
            v-else-if="props.column.field === 'caseDueDate'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.caseDueDate | dateFormat }}</span>
          </span>

          <!-- Column: Hours -->
          <span v-else-if="props.column.field === 'hours'">
            <span>
              {{ props.row.hours | hoursFormat }}
            </span>
          </span>

          <!-- Column: Video -->
          <span v-else-if="props.column.field === 'videoStatus'">
            <span v-if="props.row.videoStatus">
              <img
                v-if="props.row.videoStatus === 1"
                alt="Camera"
                src="./../../assets/images/icons/Video_Icon_Integrity.png"
                class="pr-1 cursor-pointer video-icon"
                @click="goToVideo(props.row.caseID)"
              >
              <img
                v-else
                alt="Camera"
                src="./../../assets/images/icons/Video_Icon_Claimant.png"
                class="pr-1 cursor-pointer video-icon"
                @click="goToVideo(props.row.caseID)"
              >
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing {{ displayStartIndex }} - {{ displayEndIndex }} of
                {{ totalResultCount }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['100', '150', '200']"
                class="mx-1"
                @input="
                  (value) => {
                    props.perPageChanged({ currentPerPage: value });
                    onPageSizeChanged({ currentPerPage: value });
                  }
                "
              />
              <span class="text-nowrap">per page</span>
            </div>

            <div v-if="isLoading" class="d-flex justify-content-center m-2">
              <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
            </div>

            <div>
              <b-pagination
                v-model="currentPage"
                :value="1"
                :total-rows="totalResultCount"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
                @page-click="onPageChanged"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
    <b-modal
      id="query-name-modal"
      v-model="modalShow"
      hide-backdrop
      centered
    >
      <template #modal-header="">
        <div class="height-50 p-1 pb-5">
          <div class="text-center">
            <h3 v-if="modalName === 'save'">
              Enter name of query
            </h3>
            <h3 v-if="modalName === 'delete'"> Are you sure you want to delete query <strong>{{ QueriesSelect.title }}</strong> ? This cannot be
              undone.</h3>
          </div>
          <div
            v-if="modalName === 'save'"
            class="text-center"
          >
            <b-form-input
              id="queryName"
              v-model="QueryName"
              name="queryName"
            />
            <small
              v-if="isEmpty"
              class="text-danger"
            >Field must not be empty</small>
          </div>
        </div>
      </template>
      <template #modal-footer="">
        <div class="d-flex mb-1 justify-content-center flex-grow-1">
          <div class="w-50 d-flex justify-content-center">
            <b-button
              type="reset"
              variant="outline-secondary"
              size="md"
              @click="clearModal()"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-50 d-flex justify-content-center">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              size="md"
              @click="modalName === 'save' ? confirmSaveQuery() : confirmDeleteQuery()"
            >
              {{ modalName === 'save' ? 'Save' : 'Delete' }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

import {VueGoodTable} from "vue-good-table";
import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTag,
  BFormTags,
  BModal,
  BPagination,
  BRow, BSpinner
} from "bootstrap-vue";
import APIService, {axiosInc} from "@core/utils/APIService";
import "vue-good-table/dist/vue-good-table.css";
import vSelect from "vue-select";
import {mapGetters} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const apiService = new APIService();

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormSelect,
    BFormTags,
    BFormTag,
    BButton,
    BFormInput,
    BFormGroup,
    BSpinner,
    BRow,
    BCol,
    BModal,
    vSelect,
  },
  filters: {
    dateFormat(val) {
      if (val) {
        const date = new Date(val);
        const options = {month: '2-digit', day: '2-digit', year: 'numeric'}
        if (date) return date.toLocaleDateString("en-US", options);
      }
      if (val) return val.substr(0, 10);
      return val
    },
    hoursFormat(val) {
      return val.toFixed(2);
    }
  },
  data() {
    return {
      SearchItem: "",
      QueriesSelect: "",
      SubSearchItem: "",

      oldDateType: "",
      startDateItem: "",
      endDateItem: "",

      isLoading: false,
      oldRequest: null,

      dateCreated: {
        "startDate" : null,
        "endDate": null
      },
      caseDueDate: {
        "startDate" : null,
        "endDate": null
      },
      dateClosed: {
        "startDate" : null,
        "endDate": null
      },
      completedDate: {
        "startDate" : null,
        "endDate": null
      },

      SubSearch: "",
      searchItemOptions: [
        {
          title: "Client",
          value: "clients",
        },
        {
          title: "Account Manager",
          value: "accountManagers",
        },
        {
          title: "Deal Owner",
          value: "clientDealOwnerUser",
        },
        {
          title: "Insured Name",
          value: "insuredClient",
        },
        {
          title: "Branch Account Manager",
          value: "branchAccountManager",
        },
        {
          title: "Operations Manager",
          value: "operationManagers",
        },
        {
          title: "Research Manager",
          value: "primaryAssistant",
        },
        {
          title: "SIU Manager",
          value: "secondaryAssistant",
        },
        {
          title: "RCS Manager",
          value: "uspManager",
        },
        {
          title: "Vendor Manager",
          value: "vendorManager",
        },
        {
          title: "CSR",
          value: "operationsSupport",
        },
        {
          title: "Requestor",
          value: "requesters",
        },
        {
          title: "Subject",
          value: "subjects",
        },
        {
          title: "Location",
          value: "statesList",
        },
        {
          title: "RCS Feasability",
          value: "uspFeasibilityOption",
        },
        {
          title: "Net Sweep Feasibility",
          value: "netSweepFeasibilityOption",
        },
        {
          title: "Client Instructions",
          value: "clientInstructions",
        },
        {
          title: "Case Created Date",
          value: "dateCreated",
        },
        {
          title: "Due Date",
          value: "caseDueDate",
        },
        {
          title: "Closed Date",
          value: "dateClosed",
        },
        {
          title: "Completed Date",
          value: "completedDate",
        },
      ],

      accountManagers: [],
      clientDealOwnerUser: [],
      insuredClient: [],
      operationManagers: [],
      primaryAssistant: [],
      secondaryAssistant: [],
      uspManager: [],
      vendorManager: [],
      operationsSupport: [],
      branchAccountManager: [],
      clients: [],
      requesters: [],
      subjects: [],
      statesList: [
        'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA',
        'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME',
        'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM',
        'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX',
        'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'
      ],
      uspFeasibilityOption: [
        'Yes',
        'No'
      ],
      netSweepFeasibilityOption: [
        'Yes',
        'No'
      ],
      clientInstructions : [
        {
          title: "Require authentication to view videos",
          value: "requireVideoAuthentication",
          parent: "clientInstructions"
        },
        {
          title: "Block invoice Mailing",
          value: "mailInvoices",
          parent: "clientInstructions"
        },
        {
          title: "Vending of services not allowed",
          value: "isVendingServiceNotAllowed",
          parent: "clientInstructions"
        },
      ],

      clientsById: [],
      insuredClientById: [],
      requestersById: [],
      subjectsById: [],
      liveFilters: null,
      FilterByIds: false,
      stopWatcher: false,
      sortModel: null,

      queriesSelectOptions: [],
      subSearchItemOptions: [],
      QueriesSelectedTags: [],
      QueriesSelectedItem: [],
      OldQueriesSelectedItem: [],
      emptyQuery: false,
      WorkQuery: {},
      QueryName: "",

      isEmpty: false,

      pageLength: 100,
      currentPage: 1,
      displayStartIndex: 1,
      displayEndIndex: 100,
      totalResultCount: 0,
      onlyActiveCases: true,
      fitToScreen: true,

      rows: [],

      modalShow: false,
      modalName: "",

      postData: null,
    }
  },
  computed: {
    ...mapGetters({
      getSkin: "appConfig/getSkin",
    }),
    columns: function () {
      const percent = "5vw";
      const extraPercent = "10vw";
      return [
        {
          label: "Internal Number",
          field: "fileNumber",
          width: this.fitToScreen ? percent : '150px',
          thClass: this.fitToScreen ? 'custom-th-class' : "",
          tdClass: this.fitToScreen ? 'custom-th-class' : "",
        },
        {
          label: "Account Manager",
          field: "accountManagerName",
          width: this.fitToScreen ? percent : '160px',
          thClass: this.fitToScreen ? 'custom-th-class' : "",
          tdClass: this.fitToScreen ? 'custom-th-class' : "",
        },
        {
          label: "Operations Manager",
          field: "operationsManagerName",
          width: this.fitToScreen ? percent : '180px',
          thClass: this.fitToScreen ? 'custom-th-class' : "",
          tdClass: this.fitToScreen ? 'custom-th-class' : "",
        },
        {
          label: "Client",
          field: "clientName",
          width: this.fitToScreen ? percent : '150px',
          thClass: this.fitToScreen ? 'custom-th-class' : "",
          tdClass: this.fitToScreen ? 'custom-th-class' : "",
        },
        {
          label: "Requestor",
          field: "primaryRequesterName",
          width: this.fitToScreen ? percent : '150px',
          thClass: this.fitToScreen ? 'custom-th-class' : "",
          tdClass: this.fitToScreen ? 'custom-th-class' : "",
        },
        {
          label: "Type of Claim",
          field: "typeOfClaim",
          width: this.fitToScreen ? percent : '130px',
          thClass: this.fitToScreen ? 'custom-th-class' : "",
          tdClass: this.fitToScreen ? 'custom-th-class' : "",
        },
        {
          label: "Subject",
          field: "subjectName",
          width: this.fitToScreen ? percent : '130px',
          thClass: this.fitToScreen ? 'custom-th-class' : "",
          tdClass: this.fitToScreen ? 'custom-th-class' : "",
        },
        {
          label: "Location",
          field: "Location",
          width: this.fitToScreen ? percent : '150px',
          thClass: this.fitToScreen ? 'custom-th-class' : "",
          tdClass: this.fitToScreen ? 'custom-th-class' : "",
        },
        {
          label: "Case Created Date",
          field: "dateCreated",
          width: this.fitToScreen ? percent : '130px',
          thClass: this.fitToScreen ? 'custom-th-class' : "",
          tdClass: this.fitToScreen ? 'custom-th-class' : "",
        },
        {
          label: "Scheduled Dates",
          field: "actionDueDates",
          width: this.fitToScreen ? percent : '130px',
          thClass: this.fitToScreen ? 'custom-th-class' : "",
          tdClass: this.fitToScreen ? 'custom-th-class' : "",
        },
        {
          label: "Due Date",
          field: "caseDueDate",
          width: this.fitToScreen ? percent : '100px',
          thClass: this.fitToScreen ? 'custom-th-class' : "",
          tdClass: this.fitToScreen ? 'custom-th-class' : "",
        },
        {
          label: "Hours",
          field: "hours",
          type: 'decimal',
          width: this.fitToScreen ? percent : '70px',
          thClass: this.fitToScreen ? 'custom-th-class' : "",
          tdClass: this.fitToScreen ? 'custom-th-class' : "",
        },
        {
          label: "Video",
          field: "videoStatus",
          type: "number",
          width: this.fitToScreen ? percent : '60px',
          thClass: this.fitToScreen ? 'custom-th-class' : "",
          tdClass: this.fitToScreen ? 'custom-th-class' : "",
        },
        {
          label: "Logistics",
          field: "logistics",
          width: this.fitToScreen ? extraPercent : '300px',
          thClass: this.fitToScreen ? 'custom-th-class' : "",
          tdClass: this.fitToScreen ? 'custom-th-class' : "",
        },
      ]
    }
  },
  watch: {
    onlyActiveCases: function () {
      this.loadCasesPage(1, this.getWorkQuery());
    },
    startDateItem() {
      this.fillDate(this.startDateItem, this.endDateItem)
    },
    endDateItem() {
      this.fillDate(this.startDateItem, this.endDateItem)
    },
    SearchItem(val, oldVal) {
      if (!val || val !== oldVal) {
        this.subSearchItemOptions = [];
      }
      this.startDateItem = this.endDateItem = null;
      this.SubSearchItem = [];
      if (val) {
        if (val.value === "statesList") {
          this.subSearchItemOptions = this.statesList
        } else if (val.value === "uspFeasibilityOption") {
          this.subSearchItemOptions = this.uspFeasibilityOption
        } else if (val.value === "dateCreated" || val.value === "caseDueDate" || val.value === "dateClosed" || val.value === "completedDate") {
          this.subSearchItemOptions = null;
        } else if (val.value === "netSweepFeasibilityOption") {
          this.subSearchItemOptions = this.netSweepFeasibilityOption
        } else if (val.value === "clientInstructions") {
          this.subSearchItemOptions = this.clientInstructions
        } else {
          if (this[val.value].length) this.fillSubSearchItemOptions(val.value);
        }
        this.QueriesSelectedItem.map(item => {
          let single;
          if (val.value === item.parent) {
            single = this.subSearchItemOptions.filter(i => i.value === item.value)
          }
          if (single) this.SubSearchItem.push(single[0])
        })
      } else {
        this.loadCasesPage(1)
      }
    },
    SubSearchItem(val, oldVal) {
      this.SubSearch = "";
      if (oldVal.length > val.length) {
        const removeItem = this.diff(val, oldVal)[0];
        let tagIndex;
        let itemIndex;
        if (this.SearchItem && this.SearchItem.value === removeItem.parent) {
          this.QueriesSelectedTags.map((item, index) => {
            const removableItem = item.split("(")[0].trim();
            if (removableItem === removeItem.title) tagIndex = index
          })
          const isDuplicateName = this.QueriesSelectedItem.filter(i => i.title === removeItem.title)?.length > 1
          this.QueriesSelectedItem.map((item, index) => {
            if (item.value === removeItem.value) itemIndex = index
          })
          this.spliceQueriesSelect(tagIndex, itemIndex, isDuplicateName)
        }
      } else if (val && val[val.length - 1]) {
        const tag = val[val.length - 1].title + this.tagFieldSwitcher(val[val.length - 1].parent);
        this.QueriesSelectedTags.push(tag);
        this.QueriesSelectedItem.push(val[val.length - 1]);
        this.QueriesSelectedItem = this.QueriesSelectedItem.filter((value, index, self) => {
              if (value) {
                return index === self.findIndex((t) => (
                    t && value && t.parent === value.parent && t.value === value.value
                ))
              }
            }
        )
      }
    },
    SubSearch(val) {
      if (this.SearchItem && this.SearchItem.value && val && val.length >= 3) {
        switch (this.SearchItem.value) {
          case "clients" :
            this.getClients(val)
            break
          case "insuredClient" :
            this.getInsuredClients(val)
            break
          case "requesters" :
            this.getRequester(val)
            break
          case "subjects" :
            this.getSubject(val)
            break
        }
      }
    },
    QueriesSelectedItem(val) {
      val = val.filter((value, index, self) => {
            if (value) {
              return index === self.findIndex((t) => (
                  t && value && t.parent === value.parent && t.value === value.value
              ))
            }
        }
      )
      if (this.OldQueriesSelectedItem !== JSON.stringify(val)) {
        this.OldQueriesSelectedItem = JSON.stringify(val)
        this.QueriesSelectedItem = val
        if (val.length) {
          this.loadCasesPage(this.currentPage, this.getWorkQuery());
        } else {
          this.loadCasesPage(this.currentPage);
        }
      }
      setTimeout(() => {
        if (!this.QueriesSelectedItem.length && !this.emptyQuery) {
          this.emptyQuery = true;
          this.loadCasesPage(this.currentPage, this.getWorkQuery());
        }
      }, 1500)
    },
    QueriesSelect(val) {
      this.SearchItem = "";
      this.SubSearchItem = "";
      this.clearQueriesSelect();
      if (val && val.filter && val.filter.id) {
        this.liveFilters = val.filter
        this.getFilterByIds(val.filter);
      }
    },
    FilterByIds() {
      this.queryParser(this.liveFilters);
    }
  },
  async mounted() {
    this.getFiltersData()
    this.getAllFilters();
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    preload() {
      this.statesList = this.statesList.map(item => {
        return { title: item, value: item, parent: "statesList" }
      })
      this.uspFeasibilityOption = this.uspFeasibilityOption.map(item => {
        return { title: item, value: item === 'Yes', parent: "uspFeasibilityOption" }
      })
      this.netSweepFeasibilityOption = this.netSweepFeasibilityOption.map(item => {
        return { title: item, value: item === 'Yes', parent: "netSweepFeasibilityOption" }
      })
      this.loadCasesPage(1)
    },
    loadCasesPage(pageNumber, data = {}) {
      const signal = axiosInc.CancelToken.source();
      const request = { cancel: signal.cancel, msg: "Loading..." };
      if (this.oldRequest) this.oldRequest.cancel(this.oldRequest.msg);
      this.oldRequest = request;

      this.rows = [];
      this.postData = null;

      let pageZeroIndex = pageNumber - 1;
      let startIndex = pageZeroIndex * this.pageLength;

      this.displayStartIndex = startIndex + 1;
      this.displayEndIndex = this.pageLength + this.displayStartIndex - 1;

      this.postData = {
        "onlyActiveCases": this.onlyActiveCases,
        "take": this.pageLength,
        "skip": startIndex
      }
      if (this.sortModel) {
        this.postData = {...this.postData, sortModel: this.sortModel}
      }
      this.postData = {...this.postData, ...data}

      apiService
          .post("mycases/search", this.postData, {cancelToken: signal.token})
          .then((response) => {
            if (response && response.data && response.config && response.config.data === JSON.stringify(this.postData)) {
              this.loadCaseResults(response.data);
              this.emptyQuery = false;
            }
            this.isLoading = false;
            this.oldRequest = null;
          });
    },
    getFiltersData() {
      this.getAccOpeManagers();
      this.getClients();
      this.getInsuredClients();
      this.getRequester();
      this.getSubject();
    },

    getAccOpeManagers() {
      apiService
          .get("mycases/search/values")
          .then((res) => {
            this.accountManagers = res.data.accountManagers;
            this.operationManagers = res.data.operationManagers;
            this.clientDealOwnerUser = res.data.dealOwners;
            this.branchAccountManager = res.data.branchAccountManagers;
            this.operationsSupport = res.data.CSRs;
            this.primaryAssistant = res.data.researchManagers;
            this.secondaryAssistant = res.data.siuManagers;
            this.uspManager = res.data.uspManagers;
            this.vendorManager = res.data.vendorManagers;
          });
    },

    getClients(searchParam = "") {
      apiService
          .get("clients/search?searchNameTerm=" + searchParam + "&max=100")
          .then((res) => {
            this.clients = res.data.Clients.map(item => {
              return {
                id: item.ClientID,
                name: item.Name
              }
            });
            this.clients = this.clients.concat(this.clientsById);
            if (searchParam) this.fillSubSearchItemOptions("clients");
          });
    },

    getInsuredClients(searchParam = "") {
      apiService
          .get("insured/search?searchNameTerm=" + searchParam + "&max=100&activeClients=false")
          .then((res) => {
            this.insuredClient = res.data.Clients.map(item => {
              return {
                id: item.ClientID,
                name: item.Name
              }
            });
            this.insuredClient = this.insuredClient.concat(this.insuredClientById);
            if (searchParam) {
              this.fillSubSearchItemOptions("insuredClient");
            }
          });
    },

    getRequester(searchParam = "") {
      const signal = axiosInc.CancelToken.source();
      const request = { cancel: signal.cancel, msg: "Loading..." };
      if (this.oldRequest) this.oldRequest.cancel(this.oldRequest.msg);
      this.oldRequest = request;

      const postData = {
        "entityType": "Requestor",
        "searchNameTerm": searchParam,
        "max": 1000,
      }
      apiService
          .post("search", postData, {cancelToken: signal.token})
          .then((res) => {
            if (res && res.data) {
              this.requesters = res.data.SearchResult.map(item => {
                return {
                  id: item.UserID,
                  name: item.FullName
                }
              });
              this.requesters = [...new Map(this.requesters.map(item => [item.id, item])).values()];
              this.requesters = this.requesters.concat(this.requestersById);
              if (searchParam) this.fillSubSearchItemOptions("requesters");
            }
          });
    },

    getSubject(searchParam = "") {
      const signal = axiosInc.CancelToken.source();
      const request = { cancel: signal.cancel, msg: "Loading..." };
      if (this.oldRequest) this.oldRequest.cancel(this.oldRequest.msg);
      this.oldRequest = request;

      const postData = {
        "entityType": "Subject",
        "searchNameTerm": searchParam,
        "max": 1000,
      }
      apiService
          .post("search", postData, {cancelToken: signal.token})
          .then((res) => {
            if (res && res.data) {
              this.subjects = res.data.SearchResult.map(item => {
                return {
                  id: item.SubjectID,
                  name: item.FullName
                }
              });
              this.subjects = this.subjects.concat(this.subjectsById);
              if (searchParam) this.fillSubSearchItemOptions("subjects");
            }
          });
    },
    fillSubSearchItemOptions(name) {
      this.subSearchItemOptions = this[name].map(item => {
        return {
          title: item.name,
          value: item.id,
          parent: name
        }
      })
    },
    getFilterByIds(val) {
      if (val) {
        this.isLoading = true;
        const postData = {
          "primaryRequesterIds": val.primaryRequesterIds || [],
          "subjectIds": val.subjectIds || [],
          "clientIds": val.clientIds || [],
          "insuredClientIds": val.insuredClientIds || []
        };
        console.log(postData)
        apiService
            .post("mycases/search/byids", postData)
            .then(res => {
              this.clientsById = res.data.clients;
              this.insuredClientById = res.data.insuredClients;
              this.requestersById = res.data.primaryRequesters;
              this.subjectsById = res.data.subjects;

              if (this.clientsById.length) this.clients = this.clients.concat(this.clientsById.filter(i => !this.clients.includes(i)));
              if (this.insuredClientById.length) this.insuredClient = this.insuredClient.concat(this.insuredClientById.filter(i => !this.insuredClient.includes(i)));
              this.requesters = this.requesters.concat(this.requestersById.filter(i => !this.requesters.includes(i)));
              this.subjects = this.subjects.concat(this.subjectsById.filter(i => !this.subjects.includes(i)));

              this.FilterByIds = !this.FilterByIds;
              this.stopWatcher = true;
            })
      } else {
        this.FilterByIds = !this.FilterByIds;
      }
    },
    getWorkQuery() {
      let postData = {
        "clientIds": [],
        "insuredClientIds": [],
        "accountManagerIds": [],
        "operationsManagerIds": [],
        "primaryRequesterIds": [],
        "primaryAssistantIds": [],
        "secondaryAssistantIds": [],
        "uspManagerIds": [],
        "vendorManagerIds": [],
        "operationsSupportIds": [],
        "branchAccountManagerIds": [],
        "clientDealOwnerUserIds": [],
        "clientInstructions": null,
        "subjectIds": [],
        "states": [],
        "onlyActiveCases": this.onlyActiveCases,
        "dateCreated": this.dateCreated,
        "caseDueDate": this.caseDueDate,
        "dateClosed": this.dateClosed,
        "completedDate": this.completedDate
      };
      this.QueriesSelectedItem.map(item => {
        if (item && item.parent) {
          if (item.parent === 'uspFeasibilityOption') {
            postData = {...postData, 'uspFeasibility': item.value}
          } else if (item.parent === 'netSweepFeasibilityOption') {
            postData = {...postData, 'netSweepFeasibility': item.value}
          } else if (item.parent === 'clientInstructions') {
            postData.clientInstructions = {...postData.clientInstructions, [item.value]: true}
          } else {
            postData[this.fieldsSwitcher(item.parent)].push(item.value)
          }
        }
      })
      return postData;
    },
    queryParser(val) {
      for (const valKey in val) {
        if (valKey === "id" || valKey === "name") {
          // do nothing
        } else if (val[valKey] && val[valKey].length && this[this.fieldsSwitcher(valKey)].length) {
          val[valKey].map(item => {
            if (valKey === "states") {
              this.QueriesSelectedItem.push(this[this.fieldsSwitcher(valKey)].filter(i => i.value === item)[0])
            } else {
              this.QueriesSelectedItem.push(this[this.fieldsSwitcher(valKey)].filter(i => i.id === item).map(it => {
                return{title: it.name, value: it.id, parent: this.fieldsSwitcher(valKey) }
              })[0])
            }
          })
        }
        if (valKey === "uspFeasibility") {
          this.QueriesSelectedItem.push(this.uspFeasibilityOption.filter(i => i.value === val[valKey])[0])
        }
        if (valKey === "clientInstructions") {
          if(val[valKey]) {
            const keys = Object.keys(val[valKey])
            keys.map(item => {
              this.QueriesSelectedItem.push(this.clientInstructions.filter(i => (i.value === item && val[valKey][item]))[0])
            })
          }
        }
        if (valKey === "netSweepFeasibility") {
          this.QueriesSelectedItem.push(this.netSweepFeasibilityOption.filter(i => i.value === val[valKey])[0])
        }
        if (valKey === "onlyActiveCases") this.onlyActiveCases = val[valKey]
      }
      this.QueriesSelectedItem.map(item => {
        if (item) {
          const tag = item.title + this.tagFieldSwitcher(item.parent);
          this.QueriesSelectedTags.push(tag);
        }
      })
    },

    fieldsSwitcher(val) {
      switch (val) {
        case 'clients': return "clientIds";
        case 'insuredClient': return "insuredClientIds";
        case 'accountManagers': return "accountManagerIds";
        case 'operationManagers': return "operationsManagerIds";
        case 'primaryAssistant': return "primaryAssistantIds";
        case 'secondaryAssistant': return "secondaryAssistantIds";
        case 'uspManager': return "uspManagerIds";
        case 'vendorManager': return "vendorManagerIds";
        case 'operationsSupport': return "operationsSupportIds";
        case 'branchAccountManager': return "branchAccountManagerIds";
        case 'clientDealOwnerUser': return "clientDealOwnerUserIds";
        case 'requesters': return "primaryRequesterIds";
        case 'subjects': return "subjectIds";
        case 'statesList': return "states";
        case 'clientIds': return "clients";
        case 'insuredClientIds': return "insuredClient";
        case 'accountManagerIds': return "accountManagers";
        case 'operationsManagerIds': return "operationManagers";
        case 'primaryAssistantIds': return "primaryAssistant";
        case 'secondaryAssistantIds': return "secondaryAssistant";
        case 'uspManagerIds': return "uspManager";
        case 'vendorManagerIds': return "vendorManager";
        case 'operationsSupportIds': return "operationsSupport";
        case 'branchAccountManagerIds': return "branchAccountManager";
        case 'clientDealOwnerUserIds': return "clientDealOwnerUser";
        case 'primaryRequesterIds': return "requesters";
        case 'subjectIds': return "subjects";
        case 'states': return "statesList";
        case 'clientInstructions': return "clientInstructions";
        default : return;
      }
    },
    tagFieldSwitcher(val) {
      switch (val) {
        case 'clients': return " (Client)";
        case 'insuredClient': return " (Insured Name)";
        case 'accountManagers': return " (Account Manager)";
        case 'operationManagers': return " (Operations Manager)";
        case 'primaryAssistant': return " (Research Manager)";
        case 'secondaryAssistant': return " (SIU Manager)";
        case 'uspManager': return " (RCS Manager)";
        case 'vendorManager': return " (Vendor Manager)";
        case 'branchAccountManager': return " (Branch AM)";
        case 'clientDealOwnerUser': return " (Deal Owner)";
        case 'operationsSupport': return " (CSR)";
        case 'requesters': return " (Requester)";
        case 'subjects': return " (Subject)";
        case 'statesList': return " (States)";
        case 'uspFeasibilityOption': return " (RCS Feasibility)";
        case 'netSweepFeasibilityOption': return " (Net Sweep Feasibility)";
        case 'clientInstructions': return " (Client Instructions)";
        default : return;
      }
    },
    loadCaseResults(responseData) {
      this.rows = responseData.myCases;
      this.rows.map(item => {
        return item.Location = item.city + ", " + item.state;
      })
      this.totalResultCount = responseData.totalCount;
      this.displayEndIndex = this.rows.length + this.displayStartIndex - 1;
    },
    goToVideo(CaseID) {
      this.$router.push('/cases/case-video/' + CaseID)
    },
    onPageChanged(bvEvent, page) {
      this.loadCasesPage(page, this.getWorkQuery());
    },
    onPageSizeChanged(newPageSize) {
      this.loadCasesPage(this.currentPage, this.getWorkQuery());
      this.pageLength = newPageSize.currentPerPage;
    },
    removeTag(tag) {
      const removableItem = tag.split("(")[0].trim();
      const tagIndex = this.QueriesSelectedTags.indexOf(tag);
      let itemIndex;
      this.QueriesSelectedItem.map((item, index) => {
        if (removableItem === item.value) {
          itemIndex = index;
        }
      });
      if (this.SubSearchItem && this.SubSearchItem.length) {
        this.SubSearchItem.map((item, index) => {
          if (removableItem === item.title) {
            this.SubSearchItem.splice(index, 1);
          }
        });
      }
      this.removeDateToQuery(tag)
      this.spliceQueriesSelect(tagIndex, itemIndex)
      if (this.SubSearchItem && this.SubSearchItem.length) {
        this.SubSearchItem.map((item, index) => {
          if (item.title === tag) {
            this.SubSearchItem.splice(index, 1)
          }
        })
      }
    },
    removeDateToQuery(tag) {
      if (tag.includes("Case Created Date")) {
        this.dateCreated = {
          "startDate" : null,
          "endDate": null
        }
      }
      if (tag.includes("Due Date")) {
        this.caseDueDate = {
          "startDate" : null,
          "endDate": null
        }
      }
      if (tag.includes("Closed Date")) {
        this.dateClosed = {
          "startDate" : null,
          "endDate": null
        }
      }
      if (tag.includes("Completed Date")) {
        this.completedDate = {
          "startDate" : null,
          "endDate": null
        }
      }
      this.loadCasesPage(1, this.getWorkQuery());
    },
    getAllFilters() {
      apiService
          .get("mycases/filters")
          .then(res => {
            res.data.map(item => {
              const filter = {
                title: item.name,
                filter: item
              }
              this.queriesSelectOptions.push(filter)
            })
            this.preload();
          })
    },
    saveQuery() {
      if (this.QueriesSelect && this.QueriesSelect.title) {
        this.updateQuery();
      } else {
        this.isEmpty = false;
        this.modalName = "save";
        this.modalShow = true;
      }
    },
    deleteQuery() {
      this.modalName = "delete";
      this.modalShow = true;
    },
    updateQuery() {
      let postData = {
        name: this.QueriesSelect.title,
        id: this.QueriesSelect.filter.id
      }
      postData = {...postData, ...this.getWorkQuery()}
      apiService
          .put("mycases/filters", postData)
          .then(res => {
            if (res) {
              const Filter = {
                title: this.QueriesSelect.title,
                filter: postData,
              }
              const pos = this.queriesSelectOptions.map(function(e) { return e.title; }).indexOf(this.QueriesSelect.title);
              this.queriesSelectOptions.splice(pos, 1, Filter);
              this.QueriesSelect = Filter;
              this.showToast('success', 'top-center', 4000, 'Query Updated!');
            }
          })
    },
    confirmSaveQuery() {
      if (this.QueryName) {
        this.isEmpty = false;
        let postData = {
          "name": this.QueryName
        }
        postData = {...postData, ...this.getWorkQuery()}
        apiService
            .post("mycases/filters", postData)
            .then(res => {
              if (res) {
                postData = {...postData, id: res.data}
                const Filter = {
                  title: this.QueryName,
                  filter: postData,
                }
                this.queriesSelectOptions.push(Filter);
                this.QueriesSelect = Filter;
                this.clearModal();
                this.showToast('success', 'top-center', 4000, 'Query Saved!');
              }
            })
      } else {
        this.isEmpty = true;
      }
    },
    confirmDeleteQuery() {
      const id = this.QueriesSelect.filter.id;
      if (id) {
        apiService.delete("mycases/filters/" + id)
        this.queriesSelectOptions = this.queriesSelectOptions.filter(i => i.filter.id !== id)
        this.clearQueriesSelect();
        this.showToast('success', 'top-center', 4000, 'Query Deleted!');
      } else {
        this.queriesSelectOptions = this.queriesSelectOptions.filter(i => !!i.filter.id)
      }

      this.clearModal();
      this.clearAll();
    },
    clearModal() {
      this.modalName = "";
      this.modalShow = false;
    },
    spliceQueriesSelect(tagIndex, itemIndex, isDuplicateName) {
      if (!isDuplicateName) this.QueriesSelectedTags.splice(tagIndex, 1);
      this.QueriesSelectedItem.splice(itemIndex, 1);
    },
    clearQueriesSelect() {
      this.QueriesSelectedItem = [];
      this.QueriesSelectedTags = [];
    },
    clearAll() {
      this.isLoading = true;
      this.SearchItem = "";
      this.SubSearchItem = "";
      this.QueriesSelect = "";
      this.clearQueriesSelect();
    },
    diff(a, b) {
      return a.filter(i=>!b.includes(i))
          .concat(b.filter(i=>!a.includes(i)))
    },
    sortFn(x, y) {
      return (x < y ? -1 : (x > y ? 1 : 0));
    },
    onSortChange(props) {
      const fieldName = props[0].field === "Location" ? "state" : props[0].field
      this.sortModel = {
        fieldName,
        "sort": props[0].type === "asc" ? 0 : 1
      }
      this.loadCasesPage(this.currentPage, this.getWorkQuery());
    },
    fillDate(startDate, endDate) {
      if (!this.SearchItem.value.toLowerCase().includes("date") ||
          (startDate && Number(startDate.substr(0, 4)) < 1900) ||
          (endDate && Number(endDate.substr(0, 4)) < 1900)) {
        return;
      }
      let outStartDate = null
      let outEndDate = null
      if (startDate) {
        outStartDate = new Date(startDate).toISOString()
      }
      if (endDate) {
        outEndDate = new Date(endDate).toISOString()
      }
      if (startDate && endDate && endDate < startDate) {
        this.showToast('danger', 'top-center', 4000, 'Start Date must be less than or equal to End Date');
        return;
      }
      this[this.SearchItem.value] = {
        startDate: outStartDate,
        endDate: outEndDate
      }
      const tag = this.SearchItem.title + " " + (startDate ? startDate + " - " : "All time - ") + (endDate ? endDate : "All time");
      let change = false
      this.QueriesSelectedTags.map((item, index) => {
        if (item.indexOf(this.SearchItem.title) !== -1) {
          change = true;
          this.QueriesSelectedTags.splice(index, 1, tag);
        }
      })
      if (!change) this.QueriesSelectedTags.push(tag);
      this.loadCasesPage(this.currentPage, this.getWorkQuery());
    }
  }
}
</script>

<style scoped>
.custom-th-class span {
  display: block;
  overflow: hidden;
  position: relative;
}
.video-icon {
  width: 5rem;
  padding: 0.5rem;
}
.date-input {
  margin-top: -1.7rem;
}
.clear-all-btn {
  border: 1px solid;
  border-radius: 5px;
  height: 2.4rem;
  padding: 0.4rem 1rem;
  white-space: nowrap;
}
</style>